import React from "react";
import {blast} from "@/util/additionalChains";
import {CHAIN_ID} from "@/util/constants";
import {mainnet} from "wagmi/chains";

export type NewsItem = {
  title: string;
  description: string | React.ReactNode;
  image: string;
  imageClass: string;
  ctaName: string | string[];
  ctaHref: string | string[];
  date: Date;
  endDate?: Date;
  chainId: number;
}

const newsItems: NewsItem[] = [
  {
    title: "New Spice Blast is live",
    description: <p className="text-center">
      The newest Spice Blast competition: Best PnL is live and will last for 24 hours.
    </p>,
    image: "/static/wasabi_animated_loading.svg",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Start Now"],
    ctaHref: ["/gibgold"],
    date: new Date(2024, 10, 14),
    endDate: new Date(1729008000000),
    chainId: blast.id
  },
  {
    title: "Gib Gold v5 is live",
    description: <p className="text-center">
      The newest edition of the Gib Gold trading competition is live.
      <br/>Enter the competition now to win from a prize pool of 100k Blast Gold!
    </p>,
    image: "/static/wasabi_animated_loading.svg",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Start Now"],
    ctaHref: ["/gibgold"],
    date: new Date(2024, 10, 9, 18),
    chainId: blast.id
  },
  {
    title: "Thruster Trading & Staking is Live",
    description: <p className="text-center">
      Trade now with up to 5x leverage and earn a 4x points on all trades for the
      next 7 days.
      Stake your THRUSTER tokens now to earn a 4x points boost on all deposits for the next 7 days.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/THRUSTER.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=THRUST", "/vaults?vault=sTHRUST"],
    date: new Date(2024, 10, 9),
    chainId: blast.id
  },
  {
    title: "SPX6900 Trading & Staking is Live",
    description: <p className="text-center">
      Trade now with up to 4x leverage.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/SPX6900.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=SPX", "/vaults?vault=sSPX"],
    date: new Date(2024, 10, 4),
    chainId: mainnet.id
  },
  {
    title: "Kekec Trading & Staking is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/KEKEC.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=Kekec", "/vaults?vault=sKekec"],
    date: new Date(2024, 10, 4),
    chainId: mainnet.id
  },
  {
    title: "BERRY Trading is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/BERRY.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=BERRY", "/vaults?vault=sBERRY"],
    date: new Date(2024, 10, 3),
    chainId: mainnet.id
  },
  {
    title: "EIGEN Trading is Live",
    description: <p className="text-center">
      Trade now with up to 6.9x leverage and earn a 4x points on all trades for the
      next 7 days.
      Stake your EIGEN tokens now to earn a 4x points boost on all deposits for the next 7 days.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/EIGEN.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=EIGEN", "/vaults?vault=sEIGEN"],
    date: new Date(2024, 10, 1),
    chainId: mainnet.id
  },
  {
    title: "Spice Blast 6: Stackable ROI",
    description: <p className="text-center">
      The newest Spice Blast competition is live and will last for 24 hours.
    </p>,
    image: "/static/wasabi_animated_loading.svg",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Start Now"],
    ctaHref: ["/gibgold"],
    date: new Date(2024, 9, 25),
    endDate: new Date(1727380800000),
    chainId: blast.id
  },
  {
    title: "TERMINUS Trading is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/TERMINUS.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=TERMINUS", "/vaults?vault=sTERMINUS"],
    date: new Date(2024, 9, 24),
    chainId: mainnet.id
  },
  {
    title: "Spice Blast 5: Single Highest ROI",
    description: <p className="text-center">
      The newest Spice Blast competition is live and will last for 24 hours.
    </p>,
    image: "/static/wasabi_animated_loading.svg",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Start Now"],
    ctaHref: ["/gibgold"],
    date: new Date(2024, 9, 23),
    endDate: new Date(1727193600000),
    chainId: blast.id
  },
  {
    title: "YAWN Trading is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/YAWN.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=YAWN", "/vaults?vault=sYAWN"],
    date: new Date(2024, 9, 20),
    chainId: mainnet.id
  },
  {
    title: "TURBO Trading is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/TURBO.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=TURBO", "/vaults?vault=sTURBO"],
    date: new Date(2024, 9, 19),
    chainId: mainnet.id
  },
  {
    title: "Spice Blast 4: Best PnL",
    description: <p className="text-center">
      The newest Spice Blast competition is live and will last for 24 hours.
    </p>,
    image: "/static/wasabi_animated_loading.svg",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Start Now"],
    ctaHref: ["/gibgold"],
    date: new Date(2024, 9, 18),
    endDate: new Date(1726765200000),
    chainId: blast.id
  },
  {
    title: "CATE Trading is Live",
    description: <p className="text-center">
      Trade now with up to 2x leverage. Stake your $CATE to earn spicy yield.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/CATE.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=CATE", "/vaults?vault=wCATE"],
    date: new Date(2024, 9, 11),
    chainId: mainnet.id
  },
  {
    title: "Gib Gold v4 is Live",
    description: <p className="text-center">
      The newest edition of the Gib Gold trading competition is live.
      <br/>Enter the competition now to win from a prize pool of 100k Blast Gold!
    </p>,
    image: "/static/wasabi_animated_loading.svg",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Start Now"],
    ctaHref: ["/gibgold"],
    date: new Date(2024, 9, 10),
    chainId: blast.id
  },
  {
    title: "MACHI Trading is Live",
    description: <p className="text-center">
      Trade MACHI/ETH now with up to 3x leverage and earn a 3x points on all trades for the
      next 7 days.
      Stake your MACHI tokens now to earn a 2x points boost on all deposits for the next 7 days.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/MACHI.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=MACHI", "/vaults?vault=wMACHI"],
    date: new Date(2024, 9, 10),
    chainId: blast.id
  },
  {
    title: "HYPERS Staking is Live",
    description: <p className="text-center">
      Stake your HYPERS tokens now to earn a 2x points boost on all deposits for the next 3 days.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/HYPERS.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Stake Now"],
    ctaHref: ["/vaults?vault=wHYPERS"],
    date: new Date(2024, 8, 23),
    chainId: blast.id
  },
  {
    title: "Spice Blast 3: Best Blast PnL",
    description: <p className="text-center">
      The newest Spice Blast competition is live and will last for 48 hours.
    </p>,
    image: "/static/wasabi_animated_loading.svg",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Start Now"],
    ctaHref: ["/gibgold"],
    date: new Date(2024, 8, 20),
    endDate: new Date(1724342400000),
    chainId: blast.id
  },
  {
    title: "Gib Gold v3 is Live",
    description: <p className="text-center">
      The newest edition of the Gib Gold trading competition is live.
      <br/>Enter the competition now to win from a prize pool of 100k Blast Gold!
    </p>,
    image: "/static/wasabi_animated_loading.svg",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Start Now"],
    ctaHref: ["/gibgold"],
    date: new Date(2024, 8, 12),
    chainId: blast.id
  },
  {
    title: "SOL Trading/Staking is Live",
    description: <p className="text-center">
      Trade SOL/ETH dominance now with up to 5x leverage and earn a 3x points on all trades for the
      next 7 days.
      Earn a 3x points boost on all deposits for the next 7 days.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/SOL.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=SOL", "/vaults?vault=wSOL"],
    date: new Date(2024, 8, 7),
    chainId: mainnet.id
  },
  {
    title: "WBTC Trading/Staking is Live",
    description: <p className="text-center">
      Trade WBTC/ETH dominance now with up to 5x leverage and earn a 3x points on all trades for the
      next 7 days.
      Earn a 3x points boost on all deposits for the next 7 days.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/BTC.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=WBTC", "/vaults?vault=wWBTC"],
    date: new Date(2024, 8, 5),
    chainId: blast.id
  },
  {
    title: "BITCOIN (HPOS10I) Trading is Live",
    description: <p className="text-center">
      Trade now with up to 5x leverage.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/%24BITCOIN_meme.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=BITCOIN", "/vaults?vault=wBITCOIN"],
    date: new Date(2024, 8, 1),
    chainId: mainnet.id
  },
  {
    title: "NEIRO Trading is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage and earn a 2x points on all trades for the next 7 days.
      Earn a 2x points boost on all deposits for the next 7 days.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/NEIRO.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=NEIRO", "/vaults?vault=wNEIRO"],
    date: new Date(2024, 7, 31),
    chainId: mainnet.id
  },
  {
    title: "ANDY Trading is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage and earn a 2x points on all trades for the next 7 days.
      Earn a 2x points boost on all deposits for the next 7 days.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/andy.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=ANDY", "/vaults?vault=wANDY"],
    date: new Date(2024, 7, 30),
    chainId: blast.id
  },
  {
    title: "PEPE Trading is Live",
    description: <p className="text-center">
      Trade now with up to 5x leverage.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/pepe.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=PEPE", "/vaults?vault=wPEPE"],
    date: new Date(2024, 7, 22),
    chainId: mainnet.id
  },
  {
    title: "BLAST Trading is Live",
    description: <p className="text-center">
      Trade now with up to 5x leverage.
      Earn a 5x points boost on all trades and deposits for the next 5 days.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/Blast.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=BLAST", "/vaults?vault=wBLAST"],
    date: new Date(2024, 6, 26),
    chainId: blast.id
  },
  {
    title: "YESv2 Trading is Live",
    description: <p className="text-center">
      Trade now with up to 2x leverage.
      Earn a 2x points boost on all trades and deposits for the next 48 hours
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/yes.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=YES", "/vaults?vault=wYESv2"],
    date: new Date(2024, 6, 14),
    chainId: blast.id
  },
  {
    title: "YOLO Trading is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage.
      Earn a 2x points boost on all trades and deposits for the next 48 hours
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/yolo.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=YOLO", "/vaults?vault=wYOLO"],
    date: new Date(2024, 6, 13),
    chainId: blast.id
  },
  {
    title: "bPEPE Trading is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage.
      Earn a 5x points boost on all trades and deposits for the next 30 days.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/blastinpepes.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=bPEPE", "/vaults?vault=wbPEPE"],
    date: new Date(2024, 6, 6),
    chainId: blast.id
  },
  {
    title: "DETH Trading is Live",
    description: <p className="text-center">
      Trade now with up to 5x leverage.
      Earn 2x points on all trades 0.1 ETH or larger for the next 48 hours.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/DETH.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=DETH", "/vaults?vault=wDETH"],
    date: new Date(2024, 5, 30),
    chainId: blast.id
  },
  {
    title: "KAP Trading is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/kap.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=KAP", "/vaults?vault=wKAP"],
    date: new Date(2024, 5, 24),
    chainId: blast.id
  },
  {
    title: "BAJA Trading is Live",
    description: <p className="text-center">
      Trade now with up to 2x leverage.
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/baja.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=BAJA", "/vaults?vault=wBAJA"],
    date: new Date(2024, 5, 22),
    chainId: blast.id
  },
  {
    title: "BAG Trading is Live",
    description: <p className="text-center">
      Trade now with up to 2x leverage.
      For the next 48 hours, earn 2x Blast Gold and Wasabi points on all BAG trades&deposits!
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/bag.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: ["Trade Now", "Stake Now"],
    ctaHref: ["/?token=BAG", "/vaults?vault=wbBAG"],
    date: new Date(2024, 5, 21),
    chainId: blast.id
  },
  {
    title: "bWOOL Trading is Live",
    description: <p className="text-center">
      Trade now with up to 2x leverage
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/bWOOL.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: "Trade Now",
    ctaHref: "/?token=bWOOL",
    date: new Date(2024, 5, 20),
    chainId: blast.id
  },
  {
    title: "bWOOL Vault is Live",
    description: <p className="text-center">
      For the next 72 hours, earn 2x Blast Gold and Wasabi points on all $WOOL deposits!
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/bWOOL.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: "Deposit Now",
    ctaHref: "/vaults?vault=wbWOOL",
    date: new Date(2024, 5, 18),
    chainId: blast.id
  },
  {
    title: "Glory Trading Is Live",
    description: <p className="text-center">
      Trade now with up to 2x leverage
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/glory.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: "Trade Now",
    ctaHref: "/?token=GLORY",
    date: new Date(2024, 5, 8),
    chainId: blast.id
  },
  {
    title: "ESE Trading Is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/eesee+logo.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: "Trade Now",
    ctaHref: "/?token=ESE",
    date: new Date(2024, 5, 6),
    chainId: blast.id
  },
  {
    title: "Blastopian Trading Is Live",
    description: <p className="text-center">
      Trade now with up to 3x leverage
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/blastopians.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: "Trade Now",
    ctaHref: "/?token=mBLASTOPIANS",
    date: new Date(2024, 4, 29),
    chainId: blast.id
  },
  {
    title: "Blastopian Fractionalization",
    description: <p className="text-center">
      Earn Blast Gold, Wasabi Points, Blastopian Points and yield.
      Fractionalize and stake in the <b>next 72 hours to earn a 2x boost</b> on all deposits for the rest of the season!
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/blastopians.png",
    imageClass: "rounded-full w-[100px] h-[100px]",
    ctaName: "Earn Now",
    ctaHref: "/vaults?vault=wmBLASTOPIANS&fractionalize=true",
    date: new Date(2024, 3, 25),
    chainId: blast.id
  },
  {
    title: "Orbit Is Live",
    description: <p className="text-center">
      Leverage trading and staking for Orbit Lending is now live!
      For the next 24 hours, earn 2x Blast Gold and Wasabi points on all $ORBIT deposits!
    </p>,
    image: "https://wasabi-public.s3.amazonaws.com/tokens/orbit.jpeg",
    imageClass: "rounded-full w-[100px] h-[100px] border-neutral-content border-2",
    ctaName: "Trade Now",
    ctaHref: "/?token=ORBIT",
    date: new Date(2024, 3, 24),
    chainId: blast.id
  }
]

export const getNewsItems = () => {
  return newsItems
    .filter(newsItem => newsItem.chainId === CHAIN_ID)
    .filter(newsItem => !newsItem.endDate || newsItem.endDate.getTime() > Date.now());
}
